<script lang="ts" setup>

</script>

<template>
  <v-app class="h-screen bg-gradiant">
    <!-- <UiColorModeButton class="header-buttons" /> -->
    <v-main class="overflow-y-auto h-100 w-100">
      <slot />
      <v-footer app class="f-flex align-center justify-center" color="transparent">
        <NuxtLink class="text-decoration-none text-body-2" to="/imprint">
          Impressum
        </NuxtLink>
      </v-footer>
    </v-main>
  </v-app>
</template>

<style lang="scss" scoped>
.header-buttons {
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: 2;
}
</style>
